export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyDF20hDFNX8lKRHvEzXY73-jl143SZMgjM',
    authDomain: 'presentation-insights.firebaseapp.com',
    databaseURL: 'https://presentation-insights.firebaseio.com',
    projectId: 'presentation-insights',
    storageBucket: 'presentation-insights.appspot.com',
    messagingSenderId: '764086505084'
  }
};
